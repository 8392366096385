<template>
	<seller-decoration></seller-decoration>
</template>

<script>
import SellerDecoration from '@/components/seller/shop/Decoration';

export default {
	components: {
		SellerDecoration
	}
}
</script>